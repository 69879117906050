import { Operator } from '@zencity/survey-types';
import { QuestionItem } from 'types/questions';

export interface LogicJumpFormItem {
  id: number | string;
  crossroadQuestionItem: QuestionItem;
  index: number;
  inEditMode: boolean;
  selectedDestinationQuestionId?: number;
  selectedDestinationEndScreenId?: number;
  selectedOriginQuestionId?: number;
  operator?: Operator;
  always?: boolean;
  conditionValue?: string | number;
  deleted: boolean;
}

export type LogicJumpFormItemChangeType = {
  [key in keyof LogicJumpFormItem]?: LogicJumpFormItem[key];
};

export const checkIsValidForm = (logicJumpFormItem: LogicJumpFormItem) => {
  const {
    selectedOriginQuestionId,
    selectedDestinationQuestionId,
    selectedDestinationEndScreenId,
    operator,
    conditionValue,
    always,
  } = logicJumpFormItem;

  if (always) {
    return true;
  }

  const onlyOneDestinationSelected =
    (!selectedDestinationEndScreenId && !!selectedDestinationQuestionId) ||
    (!selectedDestinationQuestionId && !!selectedDestinationEndScreenId);
  const isValidForm = onlyOneDestinationSelected && !!operator && !!conditionValue && !!selectedOriginQuestionId;
  return isValidForm;
};
