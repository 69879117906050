import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { EndScreen } from 'types/endScreen';

interface Props {
  endScreen: EndScreen;
}

export const EndScreenInfo = ({ endScreen }: Props): ReactElement => {
  const { t: translate } = useTranslation();
  const {
    url_to_redirect: urlToRedirect,
    delay_redirect: delayRedirectSeconds,
    end_screen_type: endScreenType,
  } = endScreen;
  return (
    <ul>
      <li>
        {translate('endScreen.endScreenType')}: <b>{translate(`screenType.${endScreenType}`)}</b>
      </li>
      <li>
        {translate('endScreen.redirectDelay')}: {delayRedirectSeconds}
      </li>
      {urlToRedirect && (
        <li>
          {translate('endScreen.urlToRedirect')}:{' '}
          <a href={urlToRedirect} target="_blank" rel="noreferrer">
            {urlToRedirect}
          </a>
        </li>
      )}
    </ul>
  );
};
