import { Cadence } from '@zencity/survey-types';
import { RequestStatus } from 'types/misc';
import { cadencesPerSurveyTypeMapping, SurveyType } from 'types/survey';
import { statusIsIdle } from 'utils/misc';

/**
 * Get the relevant cadences by the given survey type.
 *
 * @param surveyType - The Survey type to get its relevant cadences for.
 */
export const getRelevantCadencesBySurveyType = (surveyType?: SurveyType): Cadence[] => {
  if (!surveyType || !cadencesPerSurveyTypeMapping[surveyType]) {
    return [];
  }
  return cadencesPerSurveyTypeMapping[surveyType];
};

export const currentInstancesAreNotFetched = (
  paramSurveyId: number,
  screensFetchStatus: RequestStatus,
  currentInstanceSliceSurveyId?: number,
): boolean => currentInstanceSliceSurveyId !== paramSurveyId || statusIsIdle(screensFetchStatus);
