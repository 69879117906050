import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';
import { Survey, SurveyType } from 'types/survey';
import { QuestionItemMap } from 'components/BuildForm/BuildForm.helpers';
import { PaginatedResults } from 'types/misc';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DebounceDelay } from 'constants/misc';
import { Cadence } from '@zencity/survey-types';

const apiUrl = generateVaultApiUrl('/api/v2/survey/');
const axiosInstance = createAxiosInstance(apiUrl);
interface SurveysParams {
  page?: number;
  title?: string;
  client?: number;
  survey_type?: string;
  survey_group?: number;
  translations_languages?: string;
}

/**
 * Fetch a list of Surveys.
 */
export const fetchSurveysByParams = async (params: SurveysParams): Promise<PaginatedResults<Survey>> => {
  const response = await axiosInstance.get('', {
    params: {
      ...params,
      bypass_client_filter: true,
    },
  });

  return response.data;
};

export const debouncedFetchSurveysByParams = AwesomeDebouncePromise(fetchSurveysByParams, DebounceDelay.TEXT_INPUT);

export const fetchSurveys = createAsyncThunk('survey/fetchSurveys', fetchSurveysByParams);

/**
 * Fetch a single Survey by the given ID (and extra params).
 */
export const fetchSurveyById = async (surveyId: number, extraParams?: SurveysParams): Promise<Survey> => {
  const response = await axiosInstance.get(`${surveyId}/`, {
    params: {
      ...extraParams,
      bypass_client_filter: true,
    },
  });
  return response.data;
};

interface CreateSurveyParams {
  selectedClientId: number;
  selectedSurveyType: string;
  selectedSurveyCadence?: string;
  surveyTitleForDisplay: string;
  surveyInternalTitle: string;
  surveyRequestId?: number;
}

/**
 * Create a new survey by the given payload.
 */
export async function createSurvey(params: CreateSurveyParams): Promise<Survey> {
  const {
    selectedClientId,
    selectedSurveyType,
    selectedSurveyCadence,
    surveyTitleForDisplay,
    surveyInternalTitle,
    surveyRequestId,
  } = params;

  const response = await axiosInstance.post('', {
    survey_group: {
      title_for_display: surveyTitleForDisplay,
      client: selectedClientId,
      type: selectedSurveyType,
      cadence: selectedSurveyCadence,
    },
    internal_title: surveyInternalTitle,
    survey_request: surveyRequestId,
  });

  return response.data;
}

interface PatchSurveyParams {
  surveyId: number;
  internalTitle: string;
}

/**
 * Updating an existing Survey.
 *
 * Currently, allow patching only the internal title of a Survey.
 */
export async function updateSurvey(params: PatchSurveyParams): Promise<Survey> {
  const { internalTitle, surveyId } = params;

  const response = await axiosInstance.patch(`${surveyId}/?bypass_client_filter=true`, {
    internal_title: internalTitle,
  });

  return response.data;
}

/**
 * After reordering a list of questions for a survey, each question's index and group question needs to be changed.
 * This request updates all question's index and group question ID, even if unchanged.
 */
export async function updateSurveyQuestionIndexesAndGroups(
  surveyId: number,
  questions: Record<string, QuestionItemMap>,
): Promise<void> {
  const response = await axiosInstance.patch(
    `${surveyId}/update_questions_fields/?bypass_client_filter=true`,
    questions,
  );

  return response.data;
}

interface CloneSurveyParams {
  surveyId: number;
  internalTitle: string;
  surveyType: SurveyType;
  titleForDisplay: string;
  clientId: number;
  surveyCadence?: Cadence;
}

/**
 * Clone a survey instance, with a new internal title suffix.
 */
export const cloneSurveyInstance = async (params: CloneSurveyParams): Promise<Survey> => {
  const { surveyId, internalTitle, clientId, surveyType, titleForDisplay, surveyCadence } = params;
  const response = await axiosInstance.post(`${surveyId}/clone/?bypass_client_filter=true`, {
    internal_title: internalTitle,
    client_id: clientId,
    cadence: surveyCadence,
    survey_type: surveyType,
    title_for_display: titleForDisplay,
  });

  return response.data;
};

export const closeSurvey = async (surveyId: number): Promise<void> => {
  await axiosInstance.post(
    `${surveyId}/close/`,
    {},
    {
      params: {
        bypass_client_filter: true,
      },
    },
  );
};

export const republishSurvey = async (surveyId: number): Promise<void> => {
  await axiosInstance.post(`${surveyId}/invalidate_rfa_survey_info_cache/`, {});
};
