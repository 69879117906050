import { createAsyncThunk } from '@reduxjs/toolkit';
import { VaultPaginatedResponse } from '@zencity/survey-types';
import { LogicJumpFormItemRule } from 'components/LogicJumpDialog/LogicJumpDialog.helpers';
import { LogicJumpRuleBackoffice } from 'types/logicJump';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/question_logic_jump_rules/');
const axiosInstance = createAxiosInstance(apiUrl);

export const fetchLogicJumpsBySurveyId = async (surveyId: number): Promise<LogicJumpRuleBackoffice[]> => {
  const response = await axiosInstance.get<VaultPaginatedResponse<LogicJumpRuleBackoffice>>('', {
    params: {
      survey: surveyId,
      page_size: 100,
    },
  });
  const { results } = response.data;
  return results;
};

export const fetchLogicJumps = createAsyncThunk('logicJumps/fetchLogicJumps', fetchLogicJumpsBySurveyId);

export const createLogicJumpRule = async (logicJumpRule: LogicJumpFormItemRule): Promise<LogicJumpRuleBackoffice> => {
  const {
    crossroad_question,
    destination_type,
    expression,
    index,
    destination_end_screen,
    destination_question,
    always,
  } = logicJumpRule;
  const response = await axiosInstance.post<LogicJumpRuleBackoffice>('', {
    crossroad_question,
    destination_type,
    expression,
    index,
    destination_end_screen,
    destination_question,
    always,
  });

  return response.data;
};

export const deleteLogicJumpRule = async (logicJumpId: number): Promise<void> => {
  await axiosInstance.delete(`${logicJumpId}`);
};

export const updateLogicJumpRule = async (logicJumpRule: LogicJumpFormItemRule): Promise<LogicJumpRuleBackoffice> => {
  const {
    crossroad_question,
    destination_type,
    expression,
    index,
    destination_end_screen,
    destination_question,
    id,
    always,
  } = logicJumpRule;
  const response = await axiosInstance.patch<LogicJumpRuleBackoffice>(`${id}/`, {
    crossroad_question,
    destination_type,
    expression,
    index,
    destination_end_screen,
    destination_question,
    always,
  });

  return response.data;
};
