/* eslint-disable max-lines */
import { Button, HorizontalSeparator, ZCDSpinner } from '@zencity/common-ui';
import classnames from 'classnames';
import { AspectsDropdown } from 'components/AspectsDropdown/AspectsDropdown';
import { CheckboxAsSwitch } from 'components/CheckboxAsSwitch/CheckboxAsSwitch';
import { Flex } from 'components/Flex/Flex';
import { configSetter } from 'components/NewGenericQuestion/NewGenericQuestion.helpers';
import { DemographicTypesDropdown } from 'components/NewGenericQuestion/subComponents/DemographicTypesDropdown';
import { EditQuestionWarning } from 'components/NewGenericQuestion/subComponents/EditQuestionWarning/EditQuestionWarning';
import { NewQuestionActionsMenu } from 'components/NewGenericQuestion/subComponents/NewQuestionActionsMenu';
import { NewQuestionMainSection } from 'components/NewGenericQuestion/subComponents/NewQuestionMainSection';
import { NewQuestionRightSection } from 'components/NewGenericQuestion/subComponents/NewQuestionRightSection';
import { QuestionEditType } from 'components/QuestionBankDialog/types';
import { QuestionTextArea } from 'components/QuestionTextArea/QuestionTextArea';
import { QuestionTypeDropdown } from 'components/QuestionTypeDropdown/QuestionTypeDropdown';
import { SurveyManagerToastContext } from 'contexts/SurveyManagerToastContext';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNewGenericQuestion, updateGenericQuestion } from 'services/genericQuestion';
import { appendNewGenericQuestion, updateGenericQuestionInStore } from 'slices/genericQuestion';
import { newQuestionPayloadInitialState, setNewQuestionConfigs, setNewQuestionPayload } from 'slices/questionsBank';
import { RootState } from 'store';
import { AspectValues } from 'types/aspect';
import { QuestionType } from 'types/genericQuestion';
import { QuestionConfigType } from 'types/questionConfig';
import { logger } from 'utils/logger';
import styles from './NewGenericQuestion.module.scss';

interface Props {
  closeDialog: () => void;
  postCreationCallback: (genericQuestionId?: number) => void;
  submitBtnLabel?: string;
  addQuestionInsideGroup?: boolean;
  questionChangeType?: QuestionEditType;
}

/**
 * A component for creating a new Generic Question.
 */
// eslint-disable-next-line max-statements,max-lines-per-function,complexity
export const NewGenericQuestion: React.FC<Props> = function NewGenericQuestion(props: Props): ReactElement {
  const { toastError } = useContext(SurveyManagerToastContext);
  const {
    submitBtnLabel,
    closeDialog,
    postCreationCallback,
    addQuestionInsideGroup = false,
    questionChangeType,
  } = props;

  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { newQuestionPayload, showNumericValues } = useAppSelector((state: RootState) => state.questionsBank);
  const { aspectsSortedByLabel } = useAppSelector((state: RootState) => state.aspects);
  const { defaultScaleQuestionSteps } = useAppSelector((state: RootState) => state.globalConfigs);

  const { t: translate } = useTranslation();
  const questionIsRequired = newQuestionPayload.configs.find(
    ({ config_name }) => config_name === QuestionConfigType.REQUIRED,
  );
  const questionDescription = newQuestionPayload.configs.find(
    ({ config_name }) => config_name === QuestionConfigType.DESCRIPTION,
  );

  const demographicsAspect = aspectsSortedByLabel.find(({ value }) => value === AspectValues.DEMOGRAPHICS);
  const isDemographicAspectSelected = newQuestionPayload.aspect === demographicsAspect?.id;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onQuestionScaleChange = (value: string) =>
    dispatch(setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.STEPS, value)));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnStartAtOneConfigChange = (isChecked: boolean) => {
    dispatch(
      setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.START_AT_ONE, isChecked ? '1' : '')),
    );
  };

  useEffect(
    () => () => {
      dispatch(setNewQuestionPayload(newQuestionPayloadInitialState));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, questionChangeType],
  );

  useEffect(() => {
    if (newQuestionPayload.question_type === QuestionType.SCALE) {
      // check if someone chose scale
      onQuestionScaleChange(defaultScaleQuestionSteps.toString());

      // The default for a scale question in Vault is to not have the
      // `start_at_one` config, so it should be added on render.
      handleOnStartAtOneConfigChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultScaleQuestionSteps, newQuestionPayload.question_type]);

  const onQuestionTypeChange = (value: string) => {
    dispatch(
      setNewQuestionPayload({
        ...newQuestionPayload,
        question_type: value as QuestionType,
      }),
    );
    if (value !== QuestionType.SCALE) {
      onQuestionScaleChange('');
    }
  };
  const onQuestionTextChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(
      setNewQuestionPayload({
        ...newQuestionPayload,
        text: event.target.value,
      }),
    );

  const onQuestionAspectChange = (value: string) => {
    dispatch(
      setNewQuestionPayload({
        ...newQuestionPayload,
        aspect: parseInt(value),
        configs: isDemographicAspectSelected
          ? configSetter(newQuestionPayload, QuestionConfigType.DEMOGRAPHIC, '')
          : newQuestionPayload.configs,
      }),
    );
  };

  const toggleQuestionIsRequired = (isChecked: boolean) =>
    dispatch(
      setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.REQUIRED, isChecked ? '1' : '')),
    );
  const onQuestionDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(
      setNewQuestionConfigs(configSetter(newQuestionPayload, QuestionConfigType.DESCRIPTION, event.target.value)),
    );

  const demographicAspectHasDemographicType =
    isDemographicAspectSelected &&
    !!newQuestionPayload.configs.some((config) => config.config_name === QuestionConfigType.DEMOGRAPHIC);

  const validNumericValuesPayload = newQuestionPayload?.choices?.every(
    (choice) => !!choice.numeric_value || choice.is_non_substantiative,
  );
  const isChoicesQuestionWithChoices =
    newQuestionPayload.question_type === QuestionType.CHOICES ? !!newQuestionPayload.choices?.length : true;
  const formIsValid =
    !!newQuestionPayload.text &&
    !!newQuestionPayload.question_type &&
    !!newQuestionPayload.aspect &&
    (demographicAspectHasDemographicType || !isDemographicAspectSelected) &&
    (showNumericValues ? validNumericValuesPayload : true) &&
    isChoicesQuestionWithChoices;

  const submitNewQuestionCreationOrEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      let newInstance;
      if (newQuestionPayload.id) {
        newInstance = await updateGenericQuestion(newQuestionPayload);
        dispatch(updateGenericQuestionInStore(newInstance));
      } else {
        newInstance = await addNewGenericQuestion(newQuestionPayload);
        dispatch(appendNewGenericQuestion(newInstance));
      }
      dispatch(setNewQuestionPayload(newQuestionPayloadInitialState));
      postCreationCallback(newInstance.id);
    } catch (error) {
      toastError((error as Error).message);
      logger.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // TODO: Convert to ZCD Form Group.
  return (
    <form onSubmit={submitNewQuestionCreationOrEdit}>
      <div className={styles.newQuestionWrapper}>
        {questionChangeType === QuestionEditType.EDIT && <EditQuestionWarning />}
        <div className={styles.newQuestionGrid}>
          <Flex flexDirection="column">
            <QuestionTextArea
              placeholder={translate('questionsBankDialog.newQuestion.addQuestion')}
              value={newQuestionPayload.text}
              onChange={onQuestionTextChange}
            />
            <QuestionTextArea
              className={styles.questionDescription}
              placeholder={translate('questionsBankDialog.newQuestion.addDescription')}
              value={questionDescription?.config_value ?? ''}
              onChange={onQuestionDescriptionChange}
            />
          </Flex>
          <div>
            <QuestionTypeDropdown
              stateValue={newQuestionPayload.question_type}
              onChangeCallback={onQuestionTypeChange}
              showEmptyOption={false}
              dropdownClassName={styles.dropdown}
              omitGroupQuestionType={addQuestionInsideGroup}
            />
            <NewQuestionRightSection />
            {isDemographicAspectSelected && <DemographicTypesDropdown />}
          </div>
        </div>
        <NewQuestionMainSection />
        <HorizontalSeparator />
        <div className={styles.newQuestionFooter}>
          <AspectsDropdown
            stateValue={newQuestionPayload.aspect ? newQuestionPayload.aspect.toString() : ''}
            onChangeCallback={onQuestionAspectChange}
            dropdownClassName={styles.dropdown}
          />
          <div className={styles.verticalSeparator} />
          <CheckboxAsSwitch
            inputId="new-question-is-required"
            labelText={translate('common.required')}
            isChecked={questionIsRequired?.config_value === '1'}
            onCheckHandler={toggleQuestionIsRequired}
          />
          <NewQuestionActionsMenu />
        </div>
      </div>

      {/* Implement its own footer (`QuestionsBankDialog` makes sure its footer
          is hidden when this tab is active), due to the problematic wiring of
          actions needed on successful creation. The main problem is bubbling
          up submit handler to the `QuestionsBankDialog`.
      */}
      <div className={styles.footerForNewQuestion}>
        <Button variant="link" onClick={closeDialog} className={styles.cancelButton}>
          {translate('common.cancel')}
        </Button>
        <Button type="submit" disabled={!formIsValid || isSubmitting}>
          <>
            {isSubmitting && <ZCDSpinner size={16} color={styles.whiteColor} />}
            <p className={classnames({ [styles.marginAddText]: isSubmitting })}>
              {submitBtnLabel ||
                translate(
                  `addQuestionDialog.${
                    questionChangeType === QuestionEditType.EDIT ? 'editQuestion' : 'createNewQuestion'
                  }`,
                )}
            </p>
          </>
        </Button>
      </div>
    </form>
  );
};
