import { ZCDButton, ZCDDialog, ZCDFormGroup, ZCDInput, ZCDSelect, ZCDSpinner, zcdNotify } from '@zencity/common-ui';
import { SelectOption } from '@zencity/common-ui/lib/ZCD/ZCDFilter/types';
import { QuestionTextArea } from 'components/QuestionTextArea/QuestionTextArea';
import { useAppDispatch, useAppSelector } from 'customHooks/hooks';
import React, { ReactElement, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNewEndScreen } from 'services/endScreen';
import { RootState } from 'store';
import { EndScreenType } from 'types/endScreen';
import { Flex } from 'components/Flex/Flex';
import {
  DialogActionsType,
  SCREEN_TYPE_OPTIONS,
  initialAddEndScreenState,
  reducer,
} from 'components/NewEndScreenDialog/NewEndScreenDialog.helpers';
import { isValidUrl } from 'utils/misc';
import styles from './NewEndScreenDialog.module.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | ((state: boolean) => void);
}

/**
 * A dialog to add the End Screen component.
 */
// eslint-disable-next-line max-statements,complexity,max-lines-per-function
export const NewEndScreenDialog: React.FC<Props> = function QuestionBankDialog(props: Props): ReactElement {
  const { isOpen = false, setIsOpen } = props;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentSurveyId } = useAppSelector((state: RootState) => state.questions);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, dispatchReducer] = useReducer(reducer, initialAddEndScreenState);

  const handleScreenTypeChange = (selectedType: SelectOption): void => {
    dispatchReducer({ type: DialogActionsType.UPDATE_SCREEN_TYPE, payload: { endScreenType: selectedType } });
  };

  const handleUrlToRedirectChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatchReducer({
      type: DialogActionsType.UPDATE_URL_TO_REDIRECT,
      payload: { urlToRedirect: event.target.value },
    });
  };

  const handleRedirectDelayChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatchReducer({
      type: DialogActionsType.UPDATE_DELAY_REDIRECT,
      payload: { delayRedirect: parseInt(event.target.value) },
    });
  };

  const formIsValid = !!state.title && !!state.endScreenType;

  const submitNewEndScreen = async () => {
    if (state.urlToRedirect && !isValidUrl(state.urlToRedirect)) {
      zcdNotify.warn(translate('endScreen.invalidUrl'));
      return;
    }
    setIsSubmitting(true);
    await dispatch(
      addNewEndScreen({
        surveyId: currentSurveyId as number,
        title: state.title,
        description: state.description,
        endScreenType: state.endScreenType.value as EndScreenType,
        urlToRedirect: state.urlToRedirect ?? null,
        delayRedirect: state.delayRedirect,
      }),
    );
    setIsSubmitting(false);
    setIsOpen(false);
    dispatchReducer({ type: DialogActionsType.CLOSE });
  };

  const handleClose = (): void => {
    setIsOpen(false);
    dispatchReducer({ type: DialogActionsType.CLOSE });
  };

  // eslint-disable-next-line react/no-multi-comp
  const Footer = (): ReactElement => (
    <div className={styles.footer}>
      <ZCDButton variant="link" onClick={handleClose} text={translate('common.cancel')} />
      {isSubmitting ? (
        <ZCDSpinner size={16} color={styles.whiteColor} />
      ) : (
        <ZCDButton
          onClick={submitNewEndScreen}
          disabled={!formIsValid}
          text={translate('addQuestionDialog.addButton')}
        />
      )}
    </div>
  );

  return (
    <ZCDDialog
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      header={<h1>{translate('endScreen.newEndScreen')}</h1>}
      onRequestClose={handleClose}
      footer={<Footer />}
    >
      <form className={styles.newScreenForm}>
        <Flex width="100%" gap="24px">
          <Flex flexDirection="column" width="100%" gap="12px">
            <ZCDFormGroup className={styles.fullWidth}>
              <label htmlFor="title">{translate('common.title')}</label>
              <QuestionTextArea
                placeholder={translate('endScreen.addTitle')}
                value={state.title}
                onChange={(event) =>
                  dispatchReducer({ type: DialogActionsType.UPDATE_TITLE, payload: { title: event.target.value } })
                }
              />
            </ZCDFormGroup>
            <ZCDFormGroup className={styles.fullWidth}>
              <label htmlFor="description">{translate('common.description')}</label>
              <QuestionTextArea
                className={styles.screenDescription}
                placeholder={translate('endScreen.addDescription')}
                value={state.description}
                onChange={(event) =>
                  dispatchReducer({
                    type: DialogActionsType.UPDATE_DESC,
                    payload: { description: event.target.value },
                  })
                }
              />
            </ZCDFormGroup>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <ZCDFormGroup className={styles.fullWidth}>
              <label htmlFor="endScreenType">{translate('endScreen.endScreenType')}</label>
              <ZCDSelect
                options={SCREEN_TYPE_OPTIONS}
                value={state.endScreenType}
                onChange={(newValue) => handleScreenTypeChange(newValue as SelectOption)}
              />
            </ZCDFormGroup>
            <ZCDFormGroup className={styles.fullWidth}>
              <label htmlFor="urlToRedirect">{translate('endScreen.urlToRedirect')}</label>
              <ZCDInput type="url" onChange={handleUrlToRedirectChange} />
            </ZCDFormGroup>
            <ZCDFormGroup className={styles.fullWidth}>
              <label htmlFor="delayRedirect">{translate('endScreen.redirectDelay')}</label>
              <ZCDInput type="number" min={0} defaultValue={5} onChange={handleRedirectDelayChange} />
            </ZCDFormGroup>
          </Flex>
        </Flex>
      </form>
    </ZCDDialog>
  );
};
