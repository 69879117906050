import { MissingTranslation } from 'components/MissingTranslation/MissingTranslation';
import { NewEndScreenDialog } from 'components/NewEndScreenDialog/NewEndScreenDialog';
import { useAppDispatch, useAppSelector, useSelectedLanguage } from 'customHooks/hooks';
import React, { ReactElement, useMemo, useState } from 'react';
import { deleteEndScreen } from 'services/endScreen';
import { EndScreen as EndScreenModel } from 'types/endScreen';
import { logger } from 'utils/logger';
import { MarkdownHOC } from 'components/MarkdownHOC/MarkdownHOC';
import { RootState } from 'store';
import { LogicJumpRuleBackoffice } from 'types/logicJump';
import { deleteLogicJumpRule, fetchLogicJumps } from 'services/logicJump';
import { Flex } from 'components/Flex/Flex';
import { EndScreenInfo } from 'components/EndScreen/components/EndScreenInfo';
import { EndScreenToolbar } from './components/EndScreenToolbar';
import styles from './EndScreen.module.scss';

interface Props {
  endScreen: EndScreenModel;
  allowEditMode: boolean;
}

const deleteEndScreenDestinationLogicJumps = (
  endScreenId: number,
  logicJumpRules: LogicJumpRuleBackoffice[],
): Promise<void>[] => {
  const logicJumpsToDelete = logicJumpRules.filter(
    (logicJumpRule) => logicJumpRule.destination_end_screen === endScreenId,
  );
  return logicJumpsToDelete.map<Promise<void>>((logicJumpRule) => deleteLogicJumpRule(logicJumpRule.id));
};

/**
 * Displays the End Screen.
 */
// eslint-disable-next-line complexity,max-lines-per-function
export const EndScreen = ({ endScreen, allowEditMode = true }: Props): ReactElement => {
  const selectedLanguage = useSelectedLanguage();
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  const [addEndScreenDialogIsOpen, setAddEndScreenDialogIsOpen] = useState(false);
  const { logicJumpsPerCrossroadQuestion } = useAppSelector((state: RootState) => state.logicJumps);
  const allLogicJumps = Object.values(logicJumpsPerCrossroadQuestion)
    .map((crossroadQuestionLogicJumps) => Object.values(crossroadQuestionLogicJumps))
    .flat() as LogicJumpRuleBackoffice[];
  const dispatch = useAppDispatch();

  const screenTitleTranslated = useMemo(() => {
    if (!selectedLanguage) {
      // Use the default language title.
      return endScreen.title;
    }

    // Use the translated title.
    const translatedTitle = endScreen.translations?.title[selectedLanguage];
    if (translatedTitle) {
      return translatedTitle;
    }

    return endScreen.translations?.title[selectedLanguage] || '';
  }, [endScreen, selectedLanguage]);

  const screenDescriptionTranslated = useMemo(() => {
    if (!selectedLanguage) {
      // Use the default language description.
      return endScreen.description || '';
    }

    // Use the translated description.
    const translatedDesc = endScreen.translations?.description[selectedLanguage];
    if (translatedDesc) {
      return translatedDesc;
    }

    return endScreen.translations?.description[selectedLanguage] || '';
  }, [endScreen, selectedLanguage]);

  const handleOnMouseEnter = () => {
    setIsToolbarVisible(true);
  };

  const handleOnMouseLeave = () => {
    if (addEndScreenDialogIsOpen) {
      // Avoid hiding the toolbar when the dialog is open.
      return;
    }

    setIsToolbarVisible(false);
  };

  const handleOnDeleteClick = () => {
    try {
      const { id: endScreenId, survey } = endScreen;
      // Delete any Question Logic Jumps that have this End Screen as its destination.
      // Then, delete the End Screen.
      const allLogicJumpDeletePromises = deleteEndScreenDestinationLogicJumps(endScreenId, allLogicJumps);
      Promise.all(allLogicJumpDeletePromises).then(() => {
        // Refetch logic jumps after deletions are finished to update the slice.
        dispatch(fetchLogicJumps(survey));
        dispatch(deleteEndScreen({ endScreenId }));
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const handleOnAddClick = () => setAddEndScreenDialogIsOpen(true);

  return (
    <div className={styles.container} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <div className={styles.endScreenWrapper}>
        <div className={styles.topBorder} />
        <Flex flexDirection="column" className={styles.content} gap="12px">
          <span className={styles.title}>
            <MarkdownHOC text={screenTitleTranslated} />
            {selectedLanguage && !screenTitleTranslated && <MissingTranslation text={endScreen.title} />}
          </span>
          {endScreen.description && (
            <span className={styles.description}>
              <MarkdownHOC text={screenDescriptionTranslated} />
              {selectedLanguage && !screenDescriptionTranslated && <MissingTranslation text={endScreen.description} />}
            </span>
          )}
          <EndScreenInfo endScreen={endScreen} />
        </Flex>
        {isToolbarVisible && allowEditMode && (
          <EndScreenToolbar handleOnDeleteClick={handleOnDeleteClick} handleOnAddClick={handleOnAddClick} />
        )}
        <NewEndScreenDialog isOpen={addEndScreenDialogIsOpen} setIsOpen={setAddEndScreenDialogIsOpen} />
      </div>
    </div>
  );
};
