import { GlobalConfigsState } from 'slices/globalConfig';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/authorized_for_survey_backoffice/');
const axiosInstance = createAxiosInstance(apiUrl);

/**
 * Determine whether the user should have access to the Survey Backoffice App,
 * and return the Survey Backoffice global configs if so.
 *
 * @return
 *  The endpoint will return 200 if user is authorized and 403 if not.
 */
export async function checkIfUserIsAuthorized(): Promise<GlobalConfigsState> {
  const response = await axiosInstance.get('');

  const {
    languages,
    default_language: defaultLanguage,
    default_scale_question_steps: defaultScaleQuestionSteps,
    recurring_survey_types: recurringSurveyTypes,
    volume_tracker_url: volumeTrackerUrl,
    demographic_types: demographicTypes,
    all_survey_types: allSurveyTypes,
  } = response.data;

  return {
    languages,
    defaultLanguage,
    defaultScaleQuestionSteps,
    recurringSurveyTypes,
    volumeTrackerUrl,
    demographicTypes,
    allSurveyTypes,
  };
}
