import { createAsyncThunk } from '@reduxjs/toolkit';
import { EndScreen, EndScreenUpdatePayload, NewEndScreenPayload } from 'types/endScreen';
import { PaginatedResults, TranslationRequestPayload } from 'types/misc';
import { createAxiosInstance, generateVaultApiUrl } from 'utils/Api';

const apiUrl = generateVaultApiUrl('/api/v2/end_screens/');
const axiosInstance = createAxiosInstance(apiUrl);

/**
 * DELETE the given screen.
 */
export const deleteEndScreen = createAsyncThunk('endScreens/delete', async (payload: { endScreenId: number }) => {
  const { endScreenId } = payload;
  await axiosInstance.delete(`${endScreenId}/?bypass_client_filter=true`);
  return { deletedEndScreenId: endScreenId };
});

interface FetchEndScreensBySurveyIdParams {
  survey: number;
  translations_languages?: string;
}

/**
 * GET all the end screens for the given survey.
 */
export const fetchEndScreensBySurveyId = createAsyncThunk(
  'endScreens/fetchEndScreens',
  async (params: FetchEndScreensBySurveyIdParams) => {
    const response = await axiosInstance.get<PaginatedResults<EndScreen>>('', {
      params: {
        ...params,
        bypass_client_filter: true,
        page_size: 60,
      },
    });
    return response.data;
  },
);

/**
 * PATCH the given screen with the given payload.
 */
export const updateEndScreen = createAsyncThunk(
  'endScreens/updateEndScreen',
  async (endScreenData: EndScreenUpdatePayload) => {
    const { endScreenId, ...payload } = endScreenData;
    const response = await axiosInstance.patch<EndScreen>(`${endScreenId}/?bypass_client_filter=true`, payload);

    return response.data;
  },
);

/**
 * Update the end screen title translation.
 */
export const updateEndScreenTitleTranslation = createAsyncThunk(
  'endScreens/updateEndScreenTitle',
  async (payload: TranslationRequestPayload) => {
    const { instanceId: endScreenId, language, translatedText } = payload;
    const response = await axiosInstance.patch<EndScreen>(
      `${endScreenId}/`,
      {
        [`title_${language}`]: translatedText,
      },
      {
        params: {
          translations_languages: language,
          bypass_client_filter: true,
        },
      },
    );
    return response.data;
  },
);

/**
 * Update the end screen description translation.
 */
export const updateEndScreenDescriptionTranslation = createAsyncThunk(
  'endScreens/updateEndScreenDescription',
  async (payload: TranslationRequestPayload) => {
    const { instanceId: endScreenId, language, translatedText } = payload;
    const response = await axiosInstance.patch<EndScreen>(
      `${endScreenId}/`,
      {
        [`description_${language}`]: translatedText,
      },
      {
        params: {
          translations_languages: language,
          bypass_client_filter: true,
        },
      },
    );
    return response.data;
  },
);

/**
 * Add a new screen to the survey and update the End screen state.
 */
export const addNewEndScreen = createAsyncThunk('endScreens/addEndScreen', async (payload: NewEndScreenPayload) => {
  const { surveyId, title, endScreenType, description, urlToRedirect, delayRedirect } = payload;
  const response = await axiosInstance.post<EndScreen>('', {
    survey: surveyId,
    end_screen_type: endScreenType,
    title,
    ...(description && { description }),
    ...(urlToRedirect && { url_to_redirect: urlToRedirect }),
    delay_redirect: delayRedirect,
  });
  return { newEndScreen: response.data };
});
