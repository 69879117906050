import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ZCDButton } from '@zencity/common-ui';
import styles from './Footer.module.scss';

interface Props {
  closeDialog: () => void;
  handleOnSubmit: () => void;
  isValid: boolean;
}

export const Footer = (props: Props): ReactElement => {
  const { closeDialog, isValid, handleOnSubmit } = props;
  const { t: translate } = useTranslation();

  return (
    <div className={styles.footer}>
      <ZCDButton text={translate('common.cancel')} variant="link" onClick={closeDialog} />
      <ZCDButton text={translate('common.save')} disabled={!isValid} onClick={handleOnSubmit} />
    </div>
  );
};
