import {
  LogicJumpFormItem,
  checkIsValidForm,
} from 'components/LogicJumpDialog/components/LogicJumpForm/LogicJumpForm.helpers';
import { LogicJumpRuleBackoffice } from 'types/logicJump';
import {
  AnswerProperty,
  Conjunction,
  DestinationType,
  LogicJumpCondition,
  LogicJumpExpression,
  QuestionTypes,
} from '@zencity/survey-types';
import { Question } from 'types/questions';

export type LogicJumpFormItemRule = Omit<LogicJumpRuleBackoffice, 'id'> & {
  id: number | string;
  deleted: boolean;
};

export const SUPPORTED_QUESTION_TYPES = [QuestionTypes.CHOICES, QuestionTypes.DROPDOWN, QuestionTypes.SCALE];

const existsAtMostOneFallbackRule = (logicJumpFormItems: LogicJumpFormItem[]): boolean =>
  logicJumpFormItems.filter((logicJumpFormItem) => logicJumpFormItem.always).length <= 1;

const areAllLogicJumpFormsValid = (logicJumpFormItems: LogicJumpFormItem[]): boolean =>
  logicJumpFormItems.every((logicJumpFormItem) => checkIsValidForm(logicJumpFormItem));

export const checkAllLogicJumpFormItemsAreValid = (logicJumpFormItems: LogicJumpFormItem[]): boolean =>
  areAllLogicJumpFormsValid(logicJumpFormItems) && existsAtMostOneFallbackRule(logicJumpFormItems);

export const buildConditionFromLogicJumpForm = (
  logicJumpFormItem: LogicJumpFormItem,
  selectedOriginQuestion: Question,
): LogicJumpCondition => {
  const { operator, conditionValue } = logicJumpFormItem;
  const { question_type: questionType, id: selectedOriginQuestionId } = selectedOriginQuestion;

  let answerProperty: AnswerProperty;
  let operandB: string | number;

  // Note: This function is reached only if the form is valid, so we can assume that
  // the `conditionValue` is defined.
  switch (questionType) {
    case QuestionTypes.SCALE:
      answerProperty = AnswerProperty.VALUE;
      operandB = Number(conditionValue!);
      break;
    case QuestionTypes.CHOICES:
    case QuestionTypes.DROPDOWN:
      answerProperty = AnswerProperty.SELECTED_CHOICES;
      operandB = Number(conditionValue!);
      break;
    default:
      throw new Error(`Unsupported question type: ${questionType}`);
  }

  const condition: LogicJumpCondition = {
    operand_a_origin: {
      origin_question_id: selectedOriginQuestionId,
      answer_property: answerProperty,
    },
    operator: operator!,
    operand_b: operandB,
  };
  return condition;
};

export const convertLogicJumpFormItemsToRules = (
  logicJumpFormItems: LogicJumpFormItem[],
  questions: Question[],
): LogicJumpFormItemRule[] => {
  if (!checkAllLogicJumpFormItemsAreValid(logicJumpFormItems)) {
    throw new Error('One of the forms are invalid. Please adjust properly.');
  }
  return logicJumpFormItems.reduce<LogicJumpFormItemRule[]>((logicJumpRules, logicJumpFormItem) => {
    const {
      crossroadQuestionItem,
      selectedDestinationEndScreenId,
      selectedDestinationQuestionId,
      selectedOriginQuestionId,
      index,
      always,
      id,
      deleted,
    } = logicJumpFormItem;

    const destinationType = selectedDestinationQuestionId ? DestinationType.QUESTION : DestinationType.END_SCREEN;

    if (always) {
      if (!selectedDestinationEndScreenId && !selectedDestinationQuestionId && !deleted) {
        return logicJumpRules;
      }
      const fallbackRule: LogicJumpFormItemRule = {
        id,
        crossroad_question: crossroadQuestionItem.item.id,
        destination_type: destinationType,
        destination_end_screen: selectedDestinationEndScreenId ?? null,
        destination_question: selectedDestinationQuestionId ?? null,
        index,
        always: true,
        expression: {
          conditions: [],
          conjunction: Conjunction.OR,
        },
        deleted,
      };
      return [...logicJumpRules, fallbackRule];
    }

    const selectOriginQuestion = questions.find((question) => question.id === selectedOriginQuestionId);
    if (!selectOriginQuestion) {
      throw new Error(`Could not find the expected selected Origin Question #${selectedOriginQuestionId}.`);
    }
    const condition = buildConditionFromLogicJumpForm(logicJumpFormItem, selectOriginQuestion);
    const expression: LogicJumpExpression = {
      conditions: [condition],
      // For now, only the "OR" conjunction is supported.
      conjunction: Conjunction.OR,
    };
    return [
      ...logicJumpRules,
      {
        id,
        crossroad_question: crossroadQuestionItem.item.id,
        destination_type: destinationType,
        destination_end_screen: selectedDestinationEndScreenId ?? null,
        destination_question: selectedDestinationQuestionId ?? null,
        index,
        expression,
        always: false,
        deleted,
      },
    ];
  }, []);
};

interface LogicJumpItemRuleRequestTypes {
  postRules: LogicJumpFormItemRule[];
  patchRules: LogicJumpFormItemRule[];
  deleteRules: LogicJumpFormItemRule[];
}

export const groupFormItemRulesByRequestType = (
  logicJumpFormItemRules: LogicJumpFormItemRule[],
): LogicJumpItemRuleRequestTypes =>
  logicJumpFormItemRules.reduce<LogicJumpItemRuleRequestTypes>(
    (requestTypes, formItemRule) => {
      const { id, deleted } = formItemRule;
      if (typeof id === 'number') {
        if (deleted) {
          requestTypes.deleteRules.push(formItemRule);
        } else {
          requestTypes.patchRules.push(formItemRule);
        }
      } else {
        requestTypes.postRules.push(formItemRule);
      }
      return requestTypes;
    },
    {
      postRules: [],
      patchRules: [],
      deleteRules: [],
    },
  );
