export default {
  environment: 'production',
  apiBaseUrl: 'https://api.zencity.io',
  vaultBaseUrl: 'https://vault.zencity.io',
  insights: {
    domain: 'http://app.zencity.io',
  },
  communitySurvey: {
    PositiveSentimentGenericQuestionId: 22,
    NegativeSentimentGenericQuestionId: 23,
    CharacteristicsAspectId: 6,
  },
  surveyManager: {
    baseRouterUrl: '/admin/surveys/survey-manager',
  },
};
