import { Nullable, Translations } from './misc';

export enum EndScreenType {
  COMPLETION = 'completion',
  DISQUALIFIED = 'disqualified',
}

export interface EndScreen {
  id: number;
  survey: number;
  title: string;
  description: Nullable<string>;
  end_screen_type: EndScreenType;
  url_to_redirect: Nullable<string>;
  delay_redirect: number;
  translations?: {
    title: Translations;
    description: Translations;
  };
  updated_at: string;
}

export interface NewEndScreenPayload {
  surveyId: number;
  title: string;
  endScreenType: EndScreenType;
  urlToRedirect: Nullable<string>;
  delayRedirect: number;
  description?: string;
}

export interface EndScreenUpdatePayload {
  endScreenId: number;
  title: Nullable<string>;
  description: Nullable<string>;
}
