import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { formatMarkdownText } from 'utils/markdown';
import styles from './MarkdownHOC.module.scss';

interface Props {
  text: string;
}

export const MarkdownHOC = (props: Props): ReactElement => {
  const { text } = props;
  return <ReactMarkdown className={styles.markdown}>{formatMarkdownText(text)}</ReactMarkdown>;
};
